<template>
  <div class="bio_link_main_view">

    <div class="view_inner d-flex flex-column">

      <div class="view_top d-flex align-items-center">
        <h4 class="title">Text Block</h4>
        <div class="view-right d-flex align-items-center ml-auto">
          <bio-shortened-url></bio-shortened-url>
          <router-link class="cross_icon ml-auto" :to="{name: 'bio_links'}">
            <i class="fal fa-times"></i>
          </router-link>
        </div>
      </div>

      <div class="view_center">
        <div class="steps_dot mb-3">
          <router-link :to="{name: 'select_content'}"
                       class="btn---cta light-blue btn-round">
            <span>Go Back</span>
          </router-link>
          <div class="circles d-inline-block text-center">
            <span class="dotted_circle active"></span>
          </div>

          <button @click.prevent="validateAndStoreBlock()" class="btn---cta btn-blue btn-round">
            <span>
                            <template v-if="!getBioLinkTextSectionAdd._id">
                                Save Block
                            </template>
                            <template v-else>
                                Update Block
                            </template>
                        </span>
          </button>
        </div>

        <div class="input-block-list">
          <div class="block-heading">
            <h2>✒️ Text</h2>
            <p>Add text, title, and description to your profile with the Text Block. Add engaging text
              to your Bio-Link.</p>
          </div>

          <!-- add links section -->
          <div class="add-block-list">
            <div class="add-block-item">
              <div class="block-inner">
                <vue-editor class="text_block_editor" :placeholder="'Type or paste (Ctrl+V) your text here...'" v-model="getBioLinkTextSectionAdd.text"
                            :editorToolbar="customToolbar"></vue-editor>
                <span class="input-error" v-if="textBlockValidation.text">{{ textBlockMessages.text }}</span>
                <span class="input-error" v-else-if="textBlockValidation.textLength">{{
                    textBlockMessages.textLength
                  }}</span>

                <!-- Animation component-->
                <animation-component
                  @animationObj="animationObj($event)"
                  :animation_enabled="getBioLinkTextSectionAdd.animation_enabled"
                  :animation="getBioLinkTextSectionAdd.animation"
                ></animation-component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import BioShortenedURL from './BioShortenedURL'
import {VueEditor} from 'vue2-editor'
import {textBlockValidation, textBlockMessages} from '@/common/attributes'
import {mapActions, mapGetters} from "vuex";
import {colorsList} from "@/common/constants";

export default ({
  data () {
    return {
      customToolbar: [
        [{header: [false, 1, 2, 3, 4, 5, 6]}],
        [
          'bold',
          'italic',
          'underline',
          'link',
          'strike',
          {
            'color': colorsList
          },
          {align: "left"},
          {align: "center"},
          {align: "right"},
          {align: "justify"}
        ]
      ],
      textBlockMessages: textBlockMessages,
      textBlockValidation: JSON.parse(JSON.stringify(textBlockValidation))
    }
  },
  created () {
    this.isBioLinkFirstStepVerified()
  },
  components: {
    'bio-shortened-url': BioShortenedURL,
    'animation-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/AnimationComponent'),
    VueEditor
  },
  computed: {
    ...mapGetters(['getBioLinkTextSectionAdd'])
  },
  methods: {
    ...mapActions(['storeBioContentSection']),

    async validateAndStoreBlock () {
      this.textBlockValidation.text = this.requiredCheck(this.getBioLinkTextSectionAdd.text)
      this.textBlockValidation.textLength = this.textBlockValidation.text && !this.maxLength(this.getBioLinkTextSectionAdd.text, 10000)

      let validate = Object.keys(this.textBlockValidation).every(k => this.textBlockValidation[k] == false)

      if (!validate) {
        this.$store.dispatch('toastNotification', {message: 'Text is required.', type: 'error'})
        return
      }

      let res = await this.storeBioContentSection('getBioLinkTextSectionAdd')
      if (res && res.data.status) {
        this.$router.push({name: 'select_content'})
      }

      if(res && !res.data.status) {
        this.alertMessage(res.data.message, 'error')
      }

    },
    animationObj (event) {
      this.getBioLinkTextSectionAdd.animation_enabled = event.animation_enabled
      this.getBioLinkTextSectionAdd.animation = event.animation
    },
  },
  watch: {
    'getBioLinkTextSectionAdd.text' (value) {
      if (value && value.length > 0) {
        this.textBlockValidation.text = false
        this.textBlockValidation.textLength = !this.maxLength(value, 10000)
      }
    }
  }

})
</script>
<style lang="scss">
.text_block_editor {
  box-shadow: 0 8px 10px 0 #eaeeef;
  border-radius: 10px;
  .ql-toolbar {
    text-align: center;
    background: #F9F9F9;
    border-radius: 10px 10px 0px 0px;
    border: none !important;
    .ql-formats {
      .ql-header {
        .ql-picker-label {
          &:before {
            margin-right: 12px;
          }
        }
      }
    }
  }

  .ql-container{
    padding-bottom: 10px;
    border:none !important;
  }
  .ql-container, .ql-editor {
    background: white;
    height: 300px;
    border-radius: 0px 0px 10px 10px;
  }
}
</style>
